<template>
	<a href="https://www.copernicus.eu/en" target="_blank"><img id="copernicus-credit-login" class="" src="../../assets/images/Copernicus.svg" /></a>
</template>

<script>
export default {
	mounted() {}
};
</script>

<style lang="postcss">
#copernicus-credit-container {
	position: absolute;
	bottom: 5vh;
	width: 100%;
	display: flex;
	justify-content: center;
}
#copernicus-credit-login {
	width: 300px;
}

@media screen and (max-height: 700px) {
	#copernicus-credit-login {
		width: 250px;
	}
}

@media screen and (max-height: 620px) {
	#copernicus-credit-container {
		display: none;
	}
}
</style>
